import { createStore } from 'vuex';
import i18n from '../i18n';
import Vuex from 'vuex';
import axios from 'axios'
export default new createStore({
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' },
        ],
        token: localStorage.getItem('token') || ''
    },
    mutations: {
        
        
        auth_success(state, token){
            state.status = 'success'
            state.token = token
        },
        
        set_user(state,user){
             state.user = user;
        },
        handle_error(state,error){
            state.error = error
        },
        logout(state){
            state.status=''
            state.token=''
        },
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        
        getToken(state){
            return state.token;
        }
    },
    actions: {
        login({commit}, user){
            return new Promise((resolve,reject) => {
                commit('auth_success');
                axios.post('login', user).then(resp => {
                    const token = 'Bearer '+resp.data.data.token
                    const user = resp.data.name
                    localStorage.setItem('auth_token', token)
                    axios.defaults.headers.common['Authorization'] = token
                    commit('auth_success', token,user)
                    commit('set_user', user)
                    commit('handle_error','')
                    resolve(resp)
                    
                }).catch(error => {
                    localStorage.removeItem('auth_token')
                })
            })
        },
        logOut({commit}){
            return new Promise((resolve,reject) => {
                commit('logout')
                localStorage.removeItem('auth_token')
                delete axios.defaults.headers.common['Authorization']
                resolve();
            })
        },

        register({commit}, user){
            return new Promise((resolve,reject) => {
                commit('auth_success');
                axios.post('register', user).then(resp => {
                    const token = 'Bearer '+resp.data.data.token
                    const user = resp.data.name
                    axios.defaults.headers.common['Authorization'] = token
                    commit('auth_success', token,user)
                 
                    resolve(resp)

                }).catch(error => {
                   console.error(error)
                })
            })
        },
        
    },
    modules: {},
});

